import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './common/_guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs-menu/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'welcome',
    loadChildren: () => import('./page/welcome/welcome.module').then(m => m.WelcomePageModule),
  },
  {
    path: 'signin',
    loadChildren: () => import('./page/signin/signin.module').then(m => m.SigninPageModule),
  },
  {
    path: 'keterangan',
    loadChildren: () => import('./page/keterangan/keterangan.module').then(m => m.KeteranganPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tos',
    loadChildren: () => import('./page/tos/tos.module').then(m => m.TosPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'kontrak',
    loadChildren: () => import('./page/kontrak/kontrak.module').then(m => m.KontrakPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () => import('./page/users/users.module').then(m => m.UsersPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'mitra',
    loadChildren: () => import('./page/mitra/mitra.module').then(m => m.MitraPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'mitra-crud',
    loadChildren: () => import('./page/mitra-crud/mitra-crud.module').then(m => m.MitraCrudPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'region',
    loadChildren: () => import('./page/region/region.module').then(m => m.RegionPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'kontrak-detail',
    loadChildren: () => import('./page/kontrak-detail/kontrak-detail.module').then(m => m.KontrakDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'kontrak-add',
    loadChildren: () => import('./page/kontrak-add/kontrak-add.module').then(m => m.KontrakAddPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'kontrak-tonase',
    loadChildren: () => import('./page/kontrak-tonase/kontrak-tonase.module').then(m => m.KontrakTonasePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'kontrak-fcr',
    loadChildren: () => import('./page/kontrak-fcr/kontrak-fcr.module').then(m => m.KontrakFcrPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'kontrak-pasar',
    loadChildren: () => import('./page/kontrak-pasar/kontrak-pasar.module').then(m => m.KontrakPasarPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'unit',
    loadChildren: () => import('./page/unit/unit.module').then(m => m.UnitPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'periode-crud',
    loadChildren: () => import('./page/periode-crud/periode-crud.module').then(m => m.PeriodeCrudPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'mitra-copy',
    loadChildren: () => import('./page/mitra-copy/mitra-copy.module').then(m => m.MitraCopyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'periode-detail',
    loadChildren: () => import('./page/periode-detail/periode-detail.module').then(m => m.PeriodeDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'list-copy',
    loadChildren: () => import('./page/list-copy/list-copy.module').then(m => m.ListCopyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'kontrak-copy',
    loadChildren: () => import('./page/kontrak-copy/kontrak-copy.module').then(m => m.KontrakCopyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'avatar',
    loadChildren: () => import('./page/avatar/avatar.module').then(m => m.AvatarPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./page/faq/faq.module').then(m => m.FaqPageModule)
  },
  {
    path: 'area',
    loadChildren: () => import('./page/area/area.module').then(m => m.AreaPageModule)
  },
  {
    path: 'periode',
    loadChildren: () => import('./page/periode/periode.module').then(m => m.PeriodePageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./page/history/history.module').then( m => m.HistoryPageModule)
  },  {
    path: 'saran',
    loadChildren: () => import('./page/saran/saran.module').then( m => m.SaranPageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
