import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { timeout, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HelperService } from './common/_helper/helper.service';
import { ProfileService } from './common/_service/profile/profile.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private helperService: HelperService,
    private profileService: ProfileService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Mending dibuat pengecekan token saja.
    // this.helperService.checkExpire();

    const token = this.profileService.getToken();

    //Authentication by setting header with token value
    if (token) {
      request = request.clone({
        setHeaders: {
          token,
        },
      });
    }

    return next.handle(request).pipe(
      timeout(this.helperService.timeout),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.statusText === "OK") {
          // Unathorized  or sessions expired
          if (error.status === 401) {
            this.helperService.errorToast({
              message: 'Sesi kamu telah habis mohon login kembali.',
              position: 'top',
            });
            this.helperService.exitApp(); //If no token provided or unauthorized access
          }
          // Not Found 404
          if (error.status === 404) {
            this.helperService.errorToast({
              message: 'Data tidak ditemukan.',
              position: 'top',
            });
          }
          // Error Generally
          if (error.status === 400) {
            this.helperService.errorToast({
              message: error?.error?.msg || 'Sepertinya ada masalah.',
              position: 'top',
            });
          }
        }

        if (error instanceof TimeoutError) {
          this.helperService.errorToast({
            message: 'Koneksi timeout, pastikan jaringanmu sudah stabil.',
            position: 'bottom',
          });
        }

        if (error.statusText === "TimeoutError") {
          return throwError({
            error: { msg: 'Koneksi internet bermasalah' },
          });
        }
        if (error.status === 0) {
          if (error.statusText === "Unknown Error") {
            this.helperService.errorToast({
              message: 'Koneksi tidak stabil, mohon periksa kembali jaringanmu.',
              position: 'top',
            });
          }
        }
        return throwError(error);
      })
    );
  }
}
