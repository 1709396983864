import { Component } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { ProfileService } from './common/_service/profile/profile.service';
import { HelperService } from './common/_helper/helper.service';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import OneSignal from 'onesignal-cordova-plugin';
import { Device } from '@capacitor/device';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private helper: HelperService,
    private profileService: ProfileService, // private oneSignal: OneSignal
    private alertController: AlertController
  ) {

    this.checkWevView()

    // Hadware back button ionic
    App.addListener('backButton', ({ canGoBack }) => {
      if (!canGoBack) { App.exitApp(); }
    });

    if (Capacitor.isNativePlatform()) {
      console.log('Welcome native!');
      this.OneSignalInit();
      this.helper.checkUpdateSilent();
      window.screen.orientation.lock('portrait');
    }
  }

  async checkWevView() {
    const deviceWebView: any = (await Device.getInfo()).webViewVersion
    if (parseFloat(deviceWebView) <= 60) {
      const alert = await this.alertController.create({
        header: 'Versi Webview Usang',
        message: 'Untuk menjalankan aplikasi ini dengan baik anda perlu melakukan update webview chrome atau webkit Anda.',
        cssClass: 'alert-apps',
        mode: 'ios',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Webview',
            role: 'cancel',
            handler: () => {
              window.open(`https://play.google.com/store/apps/details?id=com.google.android.webview`)
            },
          },
          {
            role: 'oke',
            text: 'Chrome',
            cssClass: 'alert-yes',
            handler: () => {
              window.open(`https://play.google.com/store/apps/details?id=com.android.chrome`)
            },
          },
        ],
      });
      alert.present();

    }
  }

  OneSignalInit(): void {
    // NOTE: Update the setAppId value below with your OneSignal AppId.
    OneSignal.setAppId('39e8ccdb-2789-4a10-9a41-46fd327a0f16'); //App ID For AJM
    OneSignal.setNotificationOpenedHandler(function (jsonData) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });

    OneSignal.getDeviceState((stateChanges) => {
      console.log('OneSignal getDeviceState: ' + JSON.stringify(stateChanges));
      this.profileService.setPlayerID(stateChanges.userId);
    });
  }
}
