import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor() { }

  playerid: any;

  setUser(data) {
    localStorage.setItem('user', JSON.stringify(data));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  getToken() {
    return this.getUser()?.token;
  }

  getRole() {
    return this.getUser()?.role;
  }

  isAdmin() {
    return this.getUser()?.role === 'admin';
  }

  isUser() {
    return this.getUser()?.role === 'user';
  }

  isMitra() {
    return this.getUser()?.role === 'mitra';
  }

  getUsername() {
    return this.getUser()?.username;
  }

  getName() {
    return this.getUser()?.nama;
  }

  getID() {
    return this.getUser()?.id;
  }

  getIDArea() {
    return this.getUser()?.area?.id;
  }

  getNamaUnit() {
    return this.getUser()?.unit?.name;
  }

  getNamaWil() {
    return this.getUser()?.region?.name_region;
  }

  getIDWil() {
    return this.getUser()?.region.id;
  }

  getExpire() {
    return this.getUser()?.exp;
  }

  isExpired() {
    // Ubah tanggal jadi 24+ bukan 01 PM/AM tapi 13:00
    // console.log(Date.now(), Date.parse('2022-04-07 12:45'), Date.parse('2022-04-07 13:12'), Date.parse('2022-04-07 01:12'))
    return Date.now() > Date.parse(this.getExpire()) ? true : false;
  }

  isLogin() {
    return this.getUser() == undefined || this.getUser() == null ? false : true;
  }

  setPlayerID(val) {
    this.playerid = val;
  }

  getPlayerID() {
    return this.getUser()?.playerid || this.playerid;
  }
}
