import { Injectable } from '@angular/core';
import { NavController, Platform, ToastController } from '@ionic/angular';
import { ProfileService } from '../_service/profile/profile.service';
import { PrivacyScreen } from '@capacitor-community/privacy-screen';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public limit = 10; // Pagination Size
  public timeout = 10000; //Timeout 10 detik

  // Config version API
  private digitalocean = `https://ajm.nirmalab.com`;
  private production = `https://ahdalia.com`;

  public apiEndpoint = this.digitalocean;
  // public apiEndpoint = `http://localhost:9533/${this.version}`;

  apiAPP: any = 'Tidak tersedia';
  msgErr: any = 'Oops, Sepertinya ada yang salah!'; //Pesan error

  constructor(
    private navCtrl: NavController,
    private toastController: ToastController,
    private profileService: ProfileService,
    private platform: Platform,
  ) { }


  public async webVersion() {
    const result = (await Device.getInfo()).webViewVersion;
    return result;
  }

  public async checkVersion() {
    if (!Capacitor.isNativePlatform() || this.platform.is('mobileweb')) { return 'Web' } //if not an android
    const version = ((await App.getInfo()).version);
    const build = ((await App.getInfo()).build);
    return `v. ${version} | Build ${build}`;
  }

  public checkUpdate() {
    if (!Capacitor.isNativePlatform() || this.platform.is('mobileweb')) {
      this.presentToast({ message: 'Mohon gunakan mobile atau android!' })
      return;
    } //if not an android

    const openAppStore = async () => {
      await AppUpdate.openAppStore();
    };

    const performImmediateUpdate = async () => {
      const result = await AppUpdate.getAppUpdateInfo();
      if (result.currentVersion >= result.availableVersion) {
        this.presentToast({ message: 'Anda sudah menggunakan versi terbaru!' })
        return;
      }
      if (result.immediateUpdateAllowed) {
        await AppUpdate.performImmediateUpdate();
      }
    };
    performImmediateUpdate()

    const startFlexibleUpdate = async () => {
      const result = await AppUpdate.getAppUpdateInfo();
      // if (result.updateAvailability !== result.UPDATE_AVAILABLE) {
      //   return;
      // }
      if (result.flexibleUpdateAllowed) {
        await AppUpdate.startFlexibleUpdate();
      }
    };

    const completeFlexibleUpdate = async () => {
      await AppUpdate.completeFlexibleUpdate();
    };
  }

  public async checkUpdateSilent() {
    if (!this.platform.is('android') || this.platform.is('mobileweb')) { return; } //if not an android

    const result = await AppUpdate.getAppUpdateInfo();
    if (result.currentVersion >= result.availableVersion) { return; }
    if (result.immediateUpdateAllowed) { await AppUpdate.performImmediateUpdate(); }
  };

  public getNoPeriode(val) {
    const year = dayjs(val).get('year') * 12;
    const month = dayjs(val).get('month') + 1;
    return (year + month).toString();  // set week
  }

  async checkExpire() {
    const isExpired = this.profileService.isExpired();
    const isLogin = this.profileService.isLogin();

    if (isLogin) {
      if (isExpired) {
        localStorage.clear();
        this.errorToast({
          message: 'Sesi telah habis silakan login lagi.',
          position: 'bottom',
        });
        this.navCtrl.navigateRoot('/welcome');
        return true;
      }
    }
    return false;
  }

  exitApp() {
    localStorage.clear();
    this.navCtrl.navigateRoot('/welcome');
  }

  async presentToast(data) {
    const toast = await this.toastController.create({
      header: data?.header,
      message: data?.message,
      mode: 'ios',
      color: data?.color ? data.color : 'success',
      position: data?.position ? data.position : 'bottom',
      duration: 3000,
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-done-outline',
          handler: () => {
            console.log('Favorite clicked');
          },
        },
        {
          role: 'cancel',
          icon: 'close',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  async errorToast(data) {
    // console.log(data)
    const toast = await this.toastController.create({
      mode: 'ios',
      header: data?.header,
      message: data?.message,
      color: 'danger',
      position: data.position ? data.position : 'bottom',
      duration: 5000,
      buttons: [
        {
          side: 'start',
          icon: 'warning',
          handler: () => {
            console.log('Favorite clicked');
          },
        },
        {
          role: 'cancel',
          icon: 'close',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });
    await toast.present();
  }

  public async enableScreen(): Promise<void> {
    await PrivacyScreen.disable();
  }

  public async disableScreen(): Promise<void> {
    await PrivacyScreen.enable();
  }

  getIndexAvatar(index) {
    if (index < 1) index = 6;
    if (index > 6) index = 1;
    return index;
  }
  getAvatarURL(index) {
    return `assets/avatars/small/${this.getIndexAvatar(index)}.webp`
  }
}
