import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ProfileService } from '../_service/profile/profile.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private profileService: ProfileService, private navCtrl: NavController,
  ) { }
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    // return true
    const loginData = await this.profileService.getID();
    if (loginData) { return true; }
    this.navCtrl.navigateRoot('/welcome', { animated: true })
    return false;
  }

}
